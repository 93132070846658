<template>
  <div class="login-container" :style="{ display: isShow }">
    <div class="login-dialog">
      <div class="login-types" style="padding: 40px 58px 0">
        <img
          class="close-login"
          @click="closeLogin"
          src="../../assets/image/close.png"
          alt="关闭"
        />
        <div class="QR-login">
          <h2>扫描二维码登录</h2>
          <div>
            <!-- <div style="
                margin: 30px 0 12px;
                width: 180px;
                height: 180px;
                background: black;
              "></div> -->
            <div v-show="queryNum >= 75" class="fresh-code" @click="freshCode">
              已过期，请点击重新刷新
            </div>
            <Qrcode ref="qrcode" :codeKey="codeKey" />
            <p class="open-app">打开“<span>刺猬默写APP</span>”扫码登录</p>
          </div>
        </div>
        <div style="width: 200px;">
          <div class="notQR-container">
            <h2
              class="notQR-login"
              :class="{ active: passwordLogin }"
              data-way="password"
              @click="switchLoginType"
            >
              密码登录
            </h2>
            <div class="line"></div>
            <h2
              class="notQR-login"
              :class="{ active: !passwordLogin }"
              data-way="verify"
              @click="switchLoginType"
            >
              验证码登录
            </h2>
          </div>
          <!-- 密码登录表单 -->
          <el-form
            v-show="passwordLogin"
            ref="loginForm1"
            :model="form1"
            :rules="rules1"
          >
            <el-form-item prop="phone">
              <el-input
                v-model="form1.phone"
                autocomplete="off"
                placeholder="请输入手机号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item class="verify" prop="password">
              <el-input
                v-model="form1.password"
                placeholder="请输入密码"
                type="password"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="login-btn" @click="loginPassword"
                >登录</el-button
              >
            </el-form-item>
          </el-form>
          <!-- 验证码登录表单 -->
          <el-form
            v-show="!passwordLogin"
            ref="loginForm"
            :model="form"
            :rules="rules"
          >
            <el-form-item prop="phone">
              <el-input
                v-model="form.phone"
                autocomplete="off"
                placeholder="请输入手机号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item class="verify" prop="verification">
              <el-input
                v-model="form.verification"
                placeholder="请输入验证码"
              ></el-input>
              <el-button
                class="get-verify"
                type="text"
                @click="getImgSms"
                :disabled="disabled"
                >{{ getVerify }}
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="login-btn" @click="login"
                >登录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="privacy">
        登录即表示您已阅读并接受<a
          href="https://www.wodunimo.cn/appView/privacy"
          >隐私协议</a
        >及<a href="https://www.wodunimo.cn/appView/userAgreement">用户协议</a>
      </div>

      <!-- 图形验证码弹窗 -->
      <el-dialog
        title="请输入图形验证码"
        :visible.sync="dialogVisible"
        width="30%"
        :modal="false"
        :close-on-click-modal="false"
      >
        <el-input class="img-verify" v-model="imgVerification" />
        <img :src="imgVerificationUrl" alt="" />
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="getSms">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  loginWithPassword,
  getQrCodeKey,
  queryQrCodeStatus
} from "../../utils/request";
import axios from "axios";
import Qrcode from "./components/Qrcode.vue";
axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
export default {
  components: {
    Qrcode
  },
  props: ["visible"],
  data() {
    return {
      isShow: this.visible,
      codeKey: "", //二维码的key
      timer: undefined, //轮询二维码状态的定时器timer,
      queryNum: 0, //轮询次数，二维码有效期是5分钟之内，超时停止轮询，需要用户刷新二维码
      getVerify: "获取验证码",
      passwordLogin: true,
      disabled: false,
      dialogVisible: false,
      imgVerification: "", //图形验证码,
      imgVerificationUrl: "", //图形验证码图片
      form: {
        phone: "",
        verification: ""
      },
      form1: {
        phone: "",
        password: ""
      },
      rules: {
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur"
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur"
          }
        ],
        verification: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur"
          }
        ]
      },
      rules1: {
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur"
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur"
          }
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }]
      }
    };
  },
  watch: {
    visible(newValue) {
      this.isShow = newValue;
    },
    isShow(newValue) {
      if (newValue === "block") {
        getQrCodeKey().then(res => {
          this.codeKey = res.data;
          this.timer = setInterval(() => {
            this.queryNum++;
            if (this.queryNum >= 75) {
              clearInterval(this.timer);
              return;
            }
            //轮询查询二维码状态，若code为200则表示扫码登录成功，可以进行跳转
            queryQrCodeStatus(this.codeKey).then(res => {
              if (res.code === 200) {
                clearInterval(this.timer);
                localStorage.setItem("name", res.data.name);
                localStorage.setItem("token", res.data.token);
                this.$router.push({
                  path: "/parent/create"
                });
              }
            });
          }, 4000);
        });
      }
      if (newValue === "none") {
        clearInterval(this.timer);
      }
    }
  },
  methods: {
    switchLoginType(e) {
      if (e.target.dataset.way === "password" && this.passwordLogin) {
        return;
      }
      if (e.target.dataset.way === "password" && !this.passwordLogin) {
        this.$refs.loginForm.clearValidate();
        this.$refs.loginForm.resetFields();
        this.passwordLogin = true;
        return;
      }
      if (e.target.dataset.way === "verify" && this.passwordLogin) {
        this.$refs.loginForm1.clearValidate();
        this.$refs.loginForm1.resetFields();
        this.passwordLogin = false;
        return;
      }
      if (e.target.dataset.way === "verify" && !this.passwordLogin) {
        return;
      }
    },
    closeLogin() {
      this.$emit("closeLogin");
      this.form.phone = "";
      this.form.verification = "";
      this.$refs.loginForm.clearValidate(); //清空校验规则
      this.$refs.loginForm1.clearValidate(); //清空校验规则
      this.$refs.loginForm1.resetFields();
      this.$refs.loginForm1.resetFields();
    },
    getImgSms() {
      this.$refs.loginForm.validateField("phone", err => {
        if (err === "请输入手机号" || err === "请输入正确的手机号") {
          console.log(err);
          return;
        } else {
          //手机号符合要求，弹出图形验证码弹框
          axios
            .post("/get_capt", {
              tel: this.form.phone
            })
            .then(res => {
              console.log(res);
              this.imgVerificationUrl = res.data.data;
            });
          this.dialogVisible = true;
        }
      });
    },
    getSms() {
      //给用户手机发送短信验证码
      this.dialogVisible = false;
      let tel = this.form.phone;
      let text = this.imgVerification.toLowerCase();
      let timestamp = new Date().getTime() + "";
      let secret_key = "GwmF$2@Y)C5{!6zl";
      const sign = this.md5(secret_key + timestamp + text);
      axios
        .post("/dx37yzm", {
          tel,
          text,
          timestamp,
          sign
        })
        .then(res => {
          console.log(res);
          if (res.data.code === 200) {
            //成功发送短信 让按钮进入等待60s状态
            let waitTime = 60;
            this.disabled = true;
            this.getVerify = waitTime + "s后可重新发送";
            let timer = setInterval(() => {
              waitTime--;
              this.getVerify = waitTime + "s后可重新发送";
              if (waitTime === 0) {
                clearInterval(timer);
                this.disabled = false;
                this.getVerify = "获取验证码";
              }
            }, 1000);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    login() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          axios
            .post("/web_login", {
              tel: this.form.phone,
              sms: this.form.verification
            })
            .then(res => {
              console.log(res);
              if (res.data.code === 200) {
                clearInterval(this.timer);
                localStorage.setItem("name", res.data.data.name);
                localStorage.setItem("token", res.data.data.token);
                this.$router.push({
                  path: "/parent/create"
                  // query: {
                  //   name: res.data.data.name,
                  //   token: res.data.data.token
                  // }
                });
              }
            })
            .catch(err => {
              console.log(err);
            });
        } else {
          return;
        }
      });
    },
    loginPassword() {
      this.$refs.loginForm1.validate(valid => {
        if (valid) {
          loginWithPassword(this.form1)
            .then(res => {
              console.log(res);
              if (res.code === 200) {
                clearInterval(this.timer);
                localStorage.setItem("name", res.data.name);
                localStorage.setItem("token", res.data.token);
                this.$router.push({
                  path: "/parent/create"
                });
              } else {
                this.$message({
                  message: res.message,
                  type: "error"
                });
              }
            })
            .catch(error => {
              this.$message({
                message: "登陆失败，请重新登录！",
                type: "error"
              });
            });
        } else {
          return;
        }
      });
    },
    freshCode() {
      getQrCodeKey().then(res => {
        this.codeKey = res.data;
        this.queryNum = 0;
        this.timer = setInterval(() => {
          this.queryNum++;
          if (this.queryNum >= 75) {
            clearInterval(this.timer);
            return;
          }
          //轮询查询二维码状态，若code为200则表示扫码登录成功，可以进行跳转
          queryQrCodeStatus(this.codeKey).then(res => {
            if (res.code === 200) {
              clearInterval(this.timer);
              localStorage.setItem("name", res.data.name);
              localStorage.setItem("token", res.data.token);
              this.$router.push({
                path: "/parent/create"
              });
            }
          });
        }, 4000);
      });
    }
  }
};
</script>

<style lang="less">
.login-container {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.2);

  .login-dialog {
    position: relative;
    margin: 200px auto 0;
    width: 900px;
    height: 540px;
    background: #fff;
    opacity: 1;
    border-radius: 20px;

    .close-login {
      position: absolute;
      top: 30px;
      right: 30px;
      width: 12px;
      height: 12px;

      &:hover {
        cursor: pointer;
      }
    }

    h2 {
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #272829;
      opacity: 1;
      text-align: center;
    }

    .notQR-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .line {
        margin: 0 20px;
        width: 0px;
        height: 48px;
        border: 1px solid #e9e9e9;
      }
    }

    .QR-login {
      position: relative;
      .fresh-code {
        position: absolute;
        width: 270px;
        height: 270px;
        line-height: 270px;
        text-align: center;
        cursor: pointer;
        background: rgba(255, 255, 255, 0.7);
        color: #000;
      }
      .open-app {
        margin-top: 20px;
        padding-left: 10px;
        span {
          color: #2158ff;
        }
      }
    }
    .notQR-login {
      display: inline-block;
    }

    .active {
      color: #2158ff;
    }

    .el-form {
      .el-input {
        margin-top: 50px;
      }

      .el-input__inner {
        padding: 0;
        border: none;
        border-bottom: 1px solid #707070;
        border-radius: 0;
      }
    }
  }

  .login-types {
    display: flex;
    justify-content: space-between;
  }

  .verify {
    position: relative;
  }

  .get-verify {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .login-btn {
    margin-top: 20px;
    width: 100%;
    height: 64px;
  }

  .privacy {
    margin-top: 40px;
    text-align: center;
    font-size: 14px;
    color: #999999;

    a {
      color: #2158ff;
      text-decoration: none;
    }
  }

  .img-verify {
    width: 30%;
  }
}
</style>
