<template>
  <div class="all">
    <!-- logo navigation -->
    <div class="navigation">
      <img src="../assets/image/logo.png" class="logo" />
      <div class="navigation-title">
        <span @click="login">登录</span>
        <span @click="handleScroll('#function')">功能介绍</span>
        <span @click="handleScroll('#special')">产品特色</span>
        <span @click="handleScroll('#download')">APP下载</span>
      </div>
    </div>

    <!-- 登录弹窗 -->
    <Login :visible="visible" @closeLogin="visible = 'none'" />

    <!-- 主内容 -->
    <div class="header" id="download">
      <img
        src="../assets/image/header-background2.png"
        alt=""
        class="header-image"
      />
      <div class="header-content">
        <div class="title">
          刺猬默写<span>（原：我读你默）</span>，自主默写小帮手
        </div>
        <div class="info">扫码下载或在各大应用商店搜索「刺猬默写」均可下载</div>
        <div class="qrcode-wrapper">
          <div v-for="item in qrcodes" :key="item.qrname" class="qrcode">
            <div class="qrcode-img-wrapper">
              <img :src="item.qrcode" alt="" class="qrcode-img" />
            </div>
            <div class="icon-wrapper">
              <img :src="item.qricon" alt="" class="icon-img" />
              <span class="icon-name">{{ item.qrname }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 过渡 -->
    <div class="connect" id="function">
      <img src="../assets/image/route-icon.png" alt="" class="route-icon" />
      <div class="content-title">我能做什么?</div>
      <img src="../assets/image/line.png" alt="" class="line" />
    </div>

    <!-- 详细功能 -->
    <div
      class="detail-content-wrapper"
      :class="{ test: true, testenter: detailShow1 }"
    >
      <!-- <template > -->
      <div class="photo">
        <div class="detail-title">
          拍照搜索
        </div>
        <div class="detail-content">对准需要听写内容拍摄</div>
        <div class="detail-content">
          即可得到相应内容生成的听写内容
        </div>
      </div>
      <!-- </template> -->

      <img src="../assets/image/detail1.png" alt="" class="detail-img" />
    </div>
    <div
      class="detail-content-wrapper"
      :class="{ test: true, testenter: detailShow2 }"
    >
      <img src="../assets/image/detail2.png" alt="" class="detail-img" />
      <div class="photo">
        <div class="detail-title">自建默写</div>
        <div class="detail-content">自由创建，随心默写</div>
        <div class="detail-content">
          自己创建默写内容，全面满足您的默写需要
        </div>
      </div>
    </div>

    <!-- 过渡 -->
    <div class="connect" id="special">
      <img
        src="../assets/image/route-icon.png"
        alt=""
        class="route-icon"
        style="padding-top:0"
      />
      <div class="content-title">我的特色是？</div>
      <img src="../assets/image/line.png" alt="" class="line" />
    </div>

    <!-- 特色 -->
    <div class="special-wrapper">
      <div v-for="item in specialIcons" :key="item.title" class="item-wrapper">
        <img
          src="../assets/image/square-wrapper.png"
          alt=""
          class="special-bg"
        />
        <div class="special-content">
          <img :src="item.icon" alt="" />
          <div class="item-title">{{ item.title }}</div>
          <div class="item-content">{{ item.content }}</div>
        </div>
      </div>
    </div>

    <!-- footer -->
    <div class="footer-wrapper">
      <div>
        <a class="right-limit" href="https://www.wodunimo.cn/appView/privacy">
          隐私政策
        </a>
        <a
          class="right-limit"
          href="https://www.wodunimo.cn/appView/childrenPrivacy"
          >儿童隐私政策</a
        >
        <a
          class="right-limit"
          href="https://www.wodunimo.cn/appView/userAgreement"
          >用户协议</a
        >
        <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          >苏ICP备14019013号-13</a
        >
      </div>
      <div style="padding-top:1.2%">
        <span class="right-limit"
          >Copyright © 2014 无锡乐骐科技股份有限公司</span
        >
        <span>联系电话：17521697351</span>
      </div>
    </div>
  </div>
</template>

<script>
import Login from "./login/index.vue";
export default {
  components: {
    Login
  },
  data() {
    return {
      qrcodes: [
        // {
        //   qrcode: require("../assets/image/downloadQRcode.png"),
        //   qricon: require("../assets/image/pingguo.png"),
        //   qrname: "ios下载"
        // },
        {
          qrcode: require("../assets/image/downloadQRcode.png"),
          qricon: require("../assets/image/anzhuo.png"),
          qrname: "安卓下载"
        },
        {
          qrcode: require("../assets/image/gongzhonghaoQRcode.jpg"),
          qricon: require("../assets/image/gongzhonghao.png"),
          qrname: "公众号"
        }
      ],
      specialIcons: [
        {
          icon: require("../assets/image/special-icon1.png"),
          title: "数据库",
          content: "专业人员倾力打造，听写内容全面覆盖"
        },
        {
          icon: require("../assets/image/special-icon2.png"),
          title: "默写记录",
          content: "默写数据留档，记录成长时刻"
        },
        {
          icon: require("../assets/image/special-icon3.png"),
          title: "专业语音",
          content: "定制语音进行播报，告别传统“机械音”"
        }
      ],
      detailShow1: false,
      detailShow2: false,
      detailShow3: false,
      visible: "none"
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollTo);

    // const html = document.getElementsByTagName("html")[0];
    // console.log(parseFloat(html.style.fontSize) / document.body.style.zoom);
    // html.style.fontSize =
    //   parseFloat(html.style.fontSize) / document.body.style.zoom + "px";
  },
  methods: {
    handleScroll(el) {
      this.$el.querySelector(el).scrollIntoView({ behavior: "smooth" });
    },
    handleScrollTo() {
      this.currentScroll = window.pageYOffset; // 表示当前滚动的位置
      // console.log(this.currentScroll);
      // console.log(this.currentScroll == this.$refs.testref.offsetTop);
      if (this.currentScroll >= 300) {
        // 当前滚动位置到达testref的时候，显示div（100作为调整用）
        this.detailShow1 = true;
      }

      if (this.currentScroll >= 1000) {
        // 当前滚动位置到达testref的时候，显示div（100作为调整用）
        this.detailShow2 = true;
      }

      // console.log(this.currSentScroll);

      // if (this.currentScroll >= 1800) {
      //   //当前滚动位置到达testref的时候，显示div（100作为调整用）
      //   this.detailShow3 = true;
      // } else {
      //   this.detailShow3 = false;
      // }
    },
    login() {
      if (localStorage.getItem("token")) {
        this.$router.push({
          path: "/parent/create"
        });
        return;
      }
      this.visible = "block";
      // document.body.style.overflow = "hidden"; // 登录弹窗弹出时禁止页面滚动
    }
  }
};
</script>
<style lang="less" src="./Home.less"></style>
