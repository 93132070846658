import axios from "axios";
import { getToken } from "./util";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASEURL
  // timeout: 5000
});

service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers.Authorization = "JWT " + getToken();
    }
    return config;
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  res => {
    return res.data;
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
);

//密码登录
export function loginWithPassword(form) {
  return service({
    method: "post",
    url: "/passwd_login",
    data: {
      tel: form.phone,
      password: form.password
    }
  });
}

//获取用户历史创建的课件列表
export function getTextList() {
  return service({
    url: "/get_user_create_courses"
  });
}

//获取单篇课件
export function getAText(course_id) {
  return service({
    method: "post",
    url: "/get_user_course",
    data: {
      course_id
    }
  });
}

//创建课件
export function createAText(title, digest) {
  //按照指定标点分割
  // let sign = ",，。；？:：、！.;?!\n";
  let textArr = digest.split(/(?<=[,，。；？:：、！.;?!\n])/g);
  let contents = [];
  textArr.forEach((item, index) => {
    if (item === "" || item === "\n") {
      return;
    }
    contents.push({
      model: "课文",
      textfieldId: -1,
      type: 5,
      word: item
    });
  });
  return service({
    method: "post",
    url: "/create_user_course",
    headers: {
      "Content-Type": "application/json"
    },
    data: JSON.stringify({
      title,
      contents: JSON.stringify(contents)
    })
  });
}

export function editAText(title, course_id, digest) {
  let textArr = digest.split(/(?<=[,，。；？:：、！.;?!\n])/g);
  let contents = [];
  textArr.forEach((item, index) => {
    if (item === "" || item === "\n") {
      return;
    }
    contents.push({
      model: "课文",
      textfieldId: -1,
      type: 5,
      word: item
    });
  });
  return service({
    method: "post",
    url: "/edit_user_course",
    headers: {
      "Content-Type": "application/json"
    },
    data: JSON.stringify({
      title,
      course_id,
      contents: JSON.stringify(contents)
    })
  });
}


//获取二维码登录的key
export function getQrCodeKey() {
  return service({
    url: '/qrcode_get'
  })
}

//查询二维码状态
export function queryQrCodeStatus(key) {
  return service({
    method: 'post',
    url: '/qrcode_query',
    data: {
      key
    }
  })
}

//扫码登录
export function qrcodeLogin(key) {
  return service({
    method: 'post',
    url: '/qrcode_login',
    data: {
      key
    }
  })
}
